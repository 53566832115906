<template>
  <div>
    <div><h1>Utility</h1></div>
    <hr>
    <div class="content-wrapper-block">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openUtilityPage()"
      >
        Manage Clients
      </b-button>
      <br>
      <b-button
        v-if="false"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openManagerMembersPage()"
      >
        Maintain User Members
      </b-button>
      <br v-if="false">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openMergeUsers()"
      >
        Merge Internal User
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openMergeClientUsers()"
      >
        Migrate Requestor
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openNotifications()"
      >
        Notifications
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openManageVehicles()"
      >
        Manage Vehicles
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openImpactRatingEditor()"
      >
        Impact Rating Editor
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openJournalCategories()"
      >
        Journal Categories
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openManageActionTypes()"
      >
        Manage Action Types
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openChangeCaseState()"
      >
        Change Case State
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openPublicCasePassword()"
      >
        Public Case PDF Password
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openNetSuiteItems()"
      >
        Sync NetSuite Items
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openChangeCaseStatus()"
      >
        Change Case Status
      </b-button>
      <br>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="link"
        style="margin-left: 1rem"
        class="btn-link-box"
        @click="openReviewUserSessionActivity()"
      >
        Review User Session Activity
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "vue-good-table/dist/vue-good-table.css";
import store from "@/store";
import { mapGetters } from "vuex";


export default {
  components: {

    BButton,
    // eslint-disable-next-line vue/no-unused-components
  },
  directives: {
    Ripple,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  mounted() {},
  methods: {
    openUtilityPage() {
      this.$router.push('/admin/utility/client')
    },
    openManagerMembersPage() {
      this.$router.push('/admin/utility/manager-members')
    },
    openMergeUsers() {
      this.$router.push('/admin/utility/merge-users')
    },
    openMergeClientUsers() {
      this.$router.push('/admin/utility/merge-client-users')
    },
    openAdminDashboard() {
      this.$router.push('/admin/utility/admin-dashboard')
    },
    openNotifications() {
      this.$router.push('/admin/utility/notifications')
    },
    openManageActionTypes() {
      this.$router.push('/admin/utility/manage-action-types')
    },
    openJournalCategories() {
      this.$router.push('/admin/utility/journal-categories')
    },
    openChangeCaseState() {
      this.$router.push('/admin/utility/change-case-state')
    },
    openPublicCasePassword() {
      this.$router.push('/admin/utility/public-case-password')
    },
    openChangeCaseStatus() {
      this.$router.push('/admin/utility/change-case-status')
    },
    openReviewUserSessionActivity() {
      this.$router.push('/admin/utility/review-user-session-activity')
    },
    openManageVehicles() {
      this.$router.push('/admin/utility/manage-vehicles')
    },
    openImpactRatingEditor() {
      this.$router.push('/admin/utility/impact-rating-editor')
    },
    openNetSuiteItems() {
      window.location.href = 'https://cases.advantagesurveillance.com/api/billing/ns/items';
    },
  }
}


</script>

<style>
  .btn-link-box {
    border-color: #476dae !important;
    margin-top: 1rem;
    width: 160px;
  }
  .content-wrapper-block {
    display: flex;
    flex-flow: wrap;
  }
</style>
